.projects {
  // background-color: $white;
  padding-top: 5%;
  display: flex;
  width: 100%;
  justify-content: center;
  @include media("<=tablet") {
    padding-top: 18%;
  }
  @include media("<=phone") {
    padding-top: 30%;
  }

  h1 {
    display: inline;
    cursor: pointer;
    position: relative;
    // line-height: 4rem;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      display: block;
      margin-top: 5px;
      right: 0;
      background: $black;
      transition: 0.4s ease;
    }
    &:hover {
      // color: pink;
      &:after {
        width: 100%;
        left: 0;
        background: $black;
      }
    }
  }

  .projects__container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media("<=1000px") {
      width: 80%;
    }
    @include media("<=phone") {
      flex-direction: column;
    }
    .list_container {
      display: flex;
      flex-direction: row;
    }
    .project__title {
      // cursor: pointer;
      white-space: wrap;
      @include media("<=phone") {
        font-size: 1.2rem;
      }
    }

    .image-container {
      background-color: black;
    }

    .project {
      display: flex;
      flex-direction: column;
      align-items: center;
      .projects__buttons {
        display: flex;
        // align-items: center;
        justify-content: space-around;
        margin-top: 4%;
      }

      .case-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // width: 100%;
        img {
          width: 40vw;
          height: 40vh;
          object-fit: contain;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
            rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
          @include media("<=1000px") {
            width: 55vw;
          }
          @include media("<=phone") {
            width: 100vw;
            height: 62vh;
            border-radius: 0;
          }
        }
        .arrows {
          padding: 10px;
          display: flex;
          justify-content: space-between;
          position: relative;
          top: 360px;
          @include media("<=desktop") {
            top: 320px;
          }
          @include media("<=tablet") {
            top: 320px;
          }
          .cases-arrow {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.4);
            height: 72px;
            width: 72px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              color: $white;
              height: 28px;
              width: 28px;
            }
            &.disabled {
              opacity: 0.3;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.title-container {
  padding-bottom: 2rem;
  @include media("<=phone") {
    padding-bottom: 1rem;
  }
}

.right-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  @include media("<=phone") {
    margin-top: -60px;
    width: 98%;
    text-align: center;
  }
  .project-desc {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 400px;
  }
}
