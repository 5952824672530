.contact {
  .position {
    display: flex;
    justify-content: center;
    .contact__form {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      .contact__formContainer {
        width: 60%;
        display: "flex";
        flex-direction: column;
        @include media("<=654px") {
          width: 90%;
        }
        .contact__inputLabelContainer {
          display: flex;
          flex-direction: column;
          .contact__label {
            margin-bottom: 1.2rem;
            font-size: 1.6rem;
            margin-top: 1.6rem;

            @include media("<=tablet") {
              font-size: 1.2rem;
              margin-bottom: 1.4rem;
            }
          }
          .contact__input {
            font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont,
              "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
              "Droid Sans", "Helvetica Neue", sans-serif;
            padding: 12px 20px;
            margin: 8px 0;
            display: inline-block;
            font-size: 1rem;
            border-radius: 4px;
            border: 2px solid;
          }
          .first {
            margin-top: 3rem;
          }
          .textarea {
            border: 2px solid;
            resize: none;
          }
          .button {
            margin-top: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
