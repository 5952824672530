.about {
  padding-top: 10%;
  display: flex;
  width: 100%;
  justify-content: center;
  @include media("<=desktop") {
    padding-top: 13%;
  }
  @include media("<=tablet") {
    padding-top: 18%;
  }
  @include media("<=phone") {
    padding-top: 22%;
  }
  .about-container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media("<=phone") {
      width: 90%;
      flex-direction: column;
    }
    .about-story-container {
      display: flex;
      flex-direction: row;
      h3 {
        width: 80%;
        text-align: center;
        @include media("<=phone") {
          width: 100%;
          font-size: 1rem;
        }
      }
    }

    .image-container {
      cursor: pointer;
      // opacity: 0.9;
      box-sizing: border-box;
      transition: 0.4s cubic-bezier(0.6, -0.05, 0.1, 0.99);
      &:hover {
        opacity: 10;
      }
      img {
        width: 30vw;
        height: 60vh;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        @include media("<=phone") {
          width: 95vw;
          height: 53vh;
        }
      }
    }
  }
}
