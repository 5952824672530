.button {
  font-family: "Josefin Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  font-size: 1rem;
  border-radius: 4px;
  border: 2px solid;
  cursor: pointer;
}
